import styled from "styled-components";

export const HeaderContainer = styled.div`
  @media screen and (min-width: 375px) and (max-width: 449px) {
    max-width: 430px;
    margin: 0 auto;
    font-size: 14px;
    line-height: 1.2;
  }

  @media screen and (min-width: 450px) and (max-width: 768px) {
    width: 100%;
    max-width: 750px;
    font-size: 15px;
    line-height: 1.2;
  }

  @media screen and (min-width: 769px) and (max-width: 1030px) {
    width: 100%;
    max-width: 1020px;
    font-size: 16px;
    line-height: 1.2;
  }

  @media screen and (min-width: 1031px) {
    max-width: 100vw;
    font-size: 18px;
    line-height: 1.2;
  }

  position: fixed;
  z-index: 1;
  background: white;
  top: 0;
  left: 0;
  right: 0;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid white;
  border-bottom: 1px solid #5b9bd5;
`;

export const LogoThumb = styled.div`
  width: 100px;
`;

export const Logo = styled.img`
  border-radius: 14%;
  object-fit: cover;
  transform: scaleX(-1);
`;

export const NavContainer = styled.div`
  display: flex;

`;

export const AdminNavContainer = styled.div`
display: flex;
    @media screen and (min-width: 320px) and (max-width: 1030px) {
    display: none;
  }
`

export const NavMobile = styled.div`
  display: flex;

  @media screen and (min-width: 320px) and (max-width: 1030px) {
    display: none;
  }
`;

export const MobileMenu = styled.div`
  @media screen and (min-width: 320px) and (max-width: 1030px) {
    padding-right: 10px;
  }

  @media screen and (min-width: 1031px) {
    display: none;
    padding-right: 10px;
  }
`;
