import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import config from "../../config.js";

axios.defaults.baseURL = config.BACKEND_URL;

export const fetchUserStations = createAsyncThunk(
  'user/fetchUserStations',
  async (_, thunkAPI) => {
    try {
      const response = await axios.get('/user/details');
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const updateToggleState = createAsyncThunk(
  'user/updateToggleState',
  async ({ toggleState, stationId }, thunkAPI) => {
    try {
      const response = await axios.patch('/user/edit-toggle', {
        toggle_state: toggleState,
        station_id: stationId,
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const openAllStations = createAsyncThunk(
  'user/openAllStations',
  async (thunkAPI) => {
    try {
      const response = await axios.patch('/user/open-all-stations');
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const closeAllStations = createAsyncThunk(
  'user/closeAllStations',
  async (thunkAPI) => {
    try {
      const response = await axios.patch('/user/close-all-stations');
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

