import { createSlice } from '@reduxjs/toolkit';
import { fetchUsers, addUser, removeUser, updateUserPassword, updateStationsAccess, updateGPIOAccess } from './operations';

const usersSlice = createSlice({
  name: 'users',
  initialState: {
    users: [],
    isLoading: false,
    error: null,
  },
  extraReducers: builder => {
    builder
      .addCase(fetchUsers.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.users = action.payload;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(addUser.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.users.push(action.payload);
      })
      .addCase(addUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(removeUser.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(removeUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.users = state.users.filter( 
          user => user.id !== action.payload
        );
      })
      .addCase(removeUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(updateUserPassword.fulfilled, (state, { payload }) => {
        const updateUserPasswordIndex = state.users.findIndex(
          user => user.id === payload.id
        );
        if (updateUserPasswordIndex !== -1) {
          state.users[updateUserPasswordIndex] = payload;
        }
        state.error = null;
      })
      .addCase(updateUserPassword.rejected, (state, { payload }) => {
        state.error = payload;
      })
      .addCase(updateStationsAccess.fulfilled, (state, { payload }) => {
        const updateStationsAccessIndex = state.users.findIndex(
          user => user.id === payload.id
        );
        if (updateStationsAccessIndex !== -1) {
          state.users[updateStationsAccessIndex] = payload;
        }
        state.error = null;
      })
      .addCase(updateStationsAccess.rejected, (state, { payload }) => {
        state.error = payload;
      })
      .addCase(updateGPIOAccess.fulfilled, (state, { payload }) => {
        const updateUserIndex = state.users.findIndex(
          user => user.id === payload.id
        );
        if (updateUserIndex !== -1) {
          state.users[updateUserIndex] = payload;
        }
        state.error = null;
      })
      .addCase(updateGPIOAccess.rejected, (state, { payload }) => {
        state.error = payload;
      })
  },
});

export const usersReducer = usersSlice.reducer;

export default usersSlice;
