import React from "react";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import authSelectors from "redux/auth/selectors";
import { styled } from "styled-components";
import { MainNav } from "./Nav.styled";

const StyledLink = styled(NavLink)`
  font-weight: 600;
  color: black;
  font-size: 20px;
  text-decoration: none;

  &.active {
    color: #5b9bd5;
  }

  &:hover {
    color: #5b9bd5;
  }
`;

export const Nav = () => {
  const isLoggedIn = useSelector(authSelectors.selectIsLoggedIn);

  return (
    <MainNav>
      <li>
        <StyledLink to="/stations">Станції</StyledLink>
      </li>
      <li>
        <StyledLink to="/users">Користувачі</StyledLink>
      </li>
      {!isLoggedIn && (
        <li>
          <StyledLink to="/login">Вхід</StyledLink>
        </li>
      )}
    </MainNav>
  );
};
