import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 100;
  transform: translate(-50%, -50%);
  height: 34px;
  width: 34px;

  @media screen and (min-width: 449px) and (max-width: 720px) {
    height: 44px;
    width: 44px;

  }

  @media screen and (min-width: 720px) {
    height: 54px;
    width: 54px;
  }

`;