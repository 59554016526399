import { createSlice } from '@reduxjs/toolkit';
import { fetchStations, removeStation, addStation } from './operations';

const stationsSlice = createSlice({
  name: 'stations',
  initialState: {
    stations: [],
    isLoading: false,
    error: null,
  },
  extraReducers: builder => {
    builder
      .addCase(fetchStations.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchStations.fulfilled, (state, action) => {
        state.isLoading = false;
        state.stations = action.payload;
      })
      .addCase(fetchStations.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(addStation.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addStation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.stations.push(action.payload);
      })
      .addCase(addStation.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(removeStation.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(removeStation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.stations = state.stations.filter(
          station => station.id !== action.payload
        );
      })
      .addCase(removeStation.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
  },
});

export const stationsReducer = stationsSlice.reducer;

export default stationsSlice;
