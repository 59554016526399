import { Container } from './Loader.styled';
import { Hourglass } from 'react-loader-spinner';

export const Loader = () => {
  return (
    <Container>
      <Hourglass
        height="100%"
        width="100%"
        colors={['#306cce', '#72a1ed']}
        ariaLabel="loader"
      />
    </Container>
  );
};