import { NavLink } from "react-router-dom";
import { AdminNavContainer, HeaderContainer, MobileMenu, NavContainer } from "./Header.style";
import Logo from "../../assets/images/укриття_wide_mob.svg";
import { styled } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { selectIsLoggedIn } from "redux/auth/selectors";
import { AdminMenu } from "components/AdminMenu/AdminMenu";
import { Nav } from "components/Nav/Nav";
import { useAuth } from "hooks/useAuth";
import { logOut } from "redux/auth/operations";
import UserMenu from "components/UserMenu/UserMenu";
import BasicMenu from "components/MobileMenu/MobileMenu";

const StyledLink = styled(NavLink)`
  font-weight: 600;
  color: black;
  font-size: 20px;
  text-decoration: none;

  &.active {
    color: #5b9bd5;
  }

  &:hover {
    color: #5b9bd5;
  }
`;

export const Header = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const { user } = useAuth();

  if (!user) {
    return null;
  }

  const handleLogout = () => {
    // global.socket.disconnect();
    dispatch(logOut());
  };

  return (
    <HeaderContainer>
      <img src={Logo} alt="Лого" style={{ minWidth: "240px" }} />

      <NavContainer>
        {isLoggedIn && user?.role === "admin" ? (
          <AdminNavContainer>
            <Nav />
            <AdminMenu />
          </AdminNavContainer>
        ) : null}

        {isLoggedIn && user?.role === "user" ? (
          <UserMenu user={user} handleLogout={handleLogout} />
        ) : null}

        {!isLoggedIn && <StyledLink to="/login">Вхід</StyledLink>}
      </NavContainer>

      {isLoggedIn && user?.role === "admin" ? (
        <MobileMenu>
          <BasicMenu />

      {!isLoggedIn && <StyledLink to="/login">Вхід</StyledLink>}
        </MobileMenu>
      ) : null}
    </HeaderContainer>
  );
};
