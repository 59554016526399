import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const Title = styled.span`
  margin-right: 30px;
  font-style: italic;
  font-size: 18px;
`;

export const SubContainer = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
`

export const LogOut = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: black;
  text-align: center;
  cursor: pointer;

  &:hover {
    color: #5b9bd5;
  }
`;
