const { createSlice } = require("@reduxjs/toolkit");
const { fetchGPIOMessages } = require("./operations");

const gpioMessagesSlice = createSlice({
    name: 'gpioMessages',
    initialState: {
      messages: [],
      isLoading: false,
      error: null,
    },
    extraReducers: builder => {
      builder
        .addCase(fetchGPIOMessages.pending, state => {
          state.isLoading = true;
          state.error = null;
        })
        .addCase(fetchGPIOMessages.fulfilled, (state, action) => {
          state.isLoading = false;
          state.messages = action.payload;
        })
        .addCase(fetchGPIOMessages.rejected, (state, action) => {
          state.isLoading = false;
          state.error = action.payload;
        })
    },
  });
  
  export const gpioMessagesReducer = gpioMessagesSlice.reducer;

  export default gpioMessagesSlice;