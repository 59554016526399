import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import config from "../../config.js";

axios.defaults.baseURL = config.BACKEND_URL;

export const fetchStations = createAsyncThunk(
  'stations/fetchAll',
  async (_, thunkAPI) => {
    try {
      const response = await axios.get('/stations');
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const addStation = createAsyncThunk(
  'stations/addStation',
  async (station, thunkAPI) => {
    try {
      const response = await axios.post('/stations/add-new', station);
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const removeStation = createAsyncThunk(
  'stations/removeContact',
  async (stationId, thunkAPI) => {
    try {
      await axios.delete(`/stations/${stationId}`);
      return stationId;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);


