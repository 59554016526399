import React from 'react';
import { useDispatch } from 'react-redux';
import { logOut } from 'redux/auth/operations';
import { Container, LogOut, SubContainer, Title } from './AdminMenu.styled';
import { useAuth } from 'hooks/useAuth';

export const AdminMenu = () => {
  const dispatch = useDispatch();
  const { user } = useAuth();

  const handleLogout = () => {
    dispatch(logOut());
  };

  return (
    <>
    {user ? (
      <Container>
        <Title>Консоль адміністратора, {user?.name}</Title>
        <LogOut onClick={handleLogout}>
          Вийти
        </LogOut>
      </Container>
    ) : (
      <SubContainer>Вітаю!</SubContainer>
    )}
  </>
  );
};

