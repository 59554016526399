import React, { Suspense, lazy, useEffect } from "react";
import { StrictMode } from "react";
import "./App.css";
import { Routes, Route, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "components/Loader/Loader";
import { Layout } from "components/Layout/Layout";
import { selectIsLoggedIn, selectUserName } from "redux/auth/selectors";
import { fetchCurrentUser } from "redux/auth/operations";
import { RestrictedRoute } from "components/RestrictedRoute/RestrictedRoute";
import { PrivateRoute } from "components/PrivateRoute/PrivateRoute";

const Login = lazy(() => import("pages/Login"));
const Stations = lazy(() => import("pages/Stations"));
const Users = lazy(() => import("pages/Users"));
const NotFound = lazy(() => import("pages/NotFound"));
const UserView = lazy(() => import("pages/UserView"));

export const App = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const user = useSelector(selectUserName);

  useEffect(() => {
    if (!isLoggedIn) {
      dispatch(fetchCurrentUser());
    }
  }, [dispatch, isLoggedIn]);

  if (!isLoggedIn) {
    return (
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<RestrictedRoute component={Login} />} />
            <Route
              path="/login"
              element={<RestrictedRoute component={Login} />}
            />
            <Route path="*" element={<Navigate to="/" />} />
          </Route>
        </Routes>
      </Suspense>
    );
  }

  if (isLoggedIn && user?.role === "admin") {
    return (
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Stations />} />

            {/* Маршрути доступні для адміністратора */}
            <Route
              path="/users"
              element={<PrivateRoute component={Users} redirectTo="/login" />}
            />
            <Route
              path="/stations"
              element={
                <PrivateRoute component={Stations} redirectTo="/login" />
              }
            />

            {/* Маршрути, недоступні для адміністратора */}
            <Route
              path="/user"
              element={
                <RestrictedRoute component={Login} redirectTo="/stations" />
              }
            />
            <Route
              path="/login"
              element={<RestrictedRoute component={Login} redirectTo="/" />}
            />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </Suspense>
    );
  }

  if (isLoggedIn && user?.role === "user") {
    return (
      <StrictMode>
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index element={<UserView />} />

              {/* Маршрути доступні для юзера */}
              <Route
                path="/user"
                element={
                  <PrivateRoute component={UserView} redirectTo="/login" />
                }
              />
              
              {/* Маршрути, недоступні для юзера*/}
              <Route
                path="/stations"
                element={
                  <RestrictedRoute component={Login} redirectTo="/user" />
                }
              />
              <Route
                path="/users"
                element={
                  <RestrictedRoute component={Login} redirectTo="/user" />
                }
              />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </Suspense>
      </StrictMode>
    );
  }
};
