import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import config from "../../config.js";

axios.defaults.baseURL = config.BACKEND_URL;

export const fetchUsers = createAsyncThunk(
  'users/fetchAll',
  async (_, thunkAPI) => {
    try {
      const response = await axios.get('/users');
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const addUser = createAsyncThunk(
  'users/addUser',
  async (user, thunkAPI) => {
    try {
      const response = await axios.post('/users/add-new', user);
      return response.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const updateUserPassword = createAsyncThunk(
  'users/updatePassword',
  async ({ userId, password }, thunkAPI) => {
    try {
      const response = await axios.patch(`/users/edit-password/${userId}`, { password });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const updateStationsAccess = createAsyncThunk(
  'users/updateStationsAccess',
  async ({ userId, stations }, thunkAPI) => {
    try {
      const response = await axios.patch(`/users/edit-stations/${userId}`, { stations });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const removeUser = createAsyncThunk(
  'users/removeUser',
  async (userId, thunkAPI) => {
    try {
      await axios.delete(`/users/${userId}`);
      return userId;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const updateGPIOAccess = createAsyncThunk(
  'users/updateGPIOAccess',
  async ({ userId, selectedGPIOS }, thunkAPI) => {
    try {
      const response = await axios.patch(`/users/edit-gpio/${userId}`, { selectedGPIOS });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
