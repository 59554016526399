import styled from 'styled-components';

export const MainWrapper = styled.div`

@media screen and (min-width: 320px) and (max-width: 449px) {
  width: 100%;
  max-width: 430px;
  min-height: 100vh;
  margin: 0 auto;
  font-size: 14px;
  line-height: 1.2;
}

  @media screen and (min-width: 450px)and (max-width: 768px) {
    width: 100%;
    max-width: 750px;
    min-height: 100vh;
    font-size: 15px;
    line-height: 1.2;
  }

  @media screen and (min-width: 769px)and (max-width: 1030px) {
    width: 100%;
    max-width: 1020px;
    min-height: 100vh;
    font-size: 16px;
    line-height: 1.2;
  }

  width: 100%;
  margin: 0 auto;
  font-size: 18px;
  line-height: 1.4;
`