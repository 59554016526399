import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { NavLink } from "react-router-dom";
import { styled } from "styled-components";
import { useSelector } from "react-redux";
import authSelectors from "redux/auth/selectors";
import { useDispatch } from 'react-redux';
import { logOut } from 'redux/auth/operations';

const StyledLink = styled(NavLink)`
  font-weight: 600;
  color: black;
  font-size: 14px;
  text-decoration: none;

  &.active {
    color: #5b9bd5;
  }

  &.current{
    color: #5b9bd5;
  }

  &:hover {
    color: #5b9bd5;
  }
`;

export default function BasicMenu() {
  const isLoggedIn = useSelector(authSelectors.selectIsLoggedIn);
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    // global.socket.disconnect();
    dispatch(logOut());
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {isLoggedIn ? 'Меню' : 'Вхід'}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleClose}><StyledLink to="/stations">Станції</StyledLink></MenuItem>
        <MenuItem onClick={handleClose}><StyledLink to="/users">Користувачі</StyledLink></MenuItem>
        {isLoggedIn ? (
          <MenuItem onClick={handleLogout}><StyledLink to="/login">Вийти</StyledLink></MenuItem>
        ) : (
          <MenuItem onClick={handleClose}><StyledLink to="/login">Вхід</StyledLink></MenuItem>
        )}
      </Menu>
    </div>
  );
}
