import { createSlice } from "@reduxjs/toolkit";
import { closeAllStations, fetchUserStations, openAllStations, updateToggleState } from "./operation";

const userStationsSlice = createSlice({
  name: "userStations",
  initialState: {
    stations: [],
    isLoading: false,
    error: null,
  },
  reducers: {
    updateStationsList: (state, action) => {
      state.stations = action.payload;
      state.isLoading = true;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserStations.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchUserStations.fulfilled, (state, action) => {
        state.isLoading = false;
        state.stations = action.payload;
      })
      .addCase(fetchUserStations.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(updateToggleState.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateToggleState.fulfilled, (state, action) => {
        const { toggleState, stationId } = action.payload;
        const stationToUpdate = state.stations.find(
          (station) => station.stationId === stationId
        );

        if (stationToUpdate) {
          stationToUpdate.stationMetrics.door = toggleState;
        }

        state.isLoading = false;
        state.error = null;
      })
      .addCase(updateToggleState.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(openAllStations.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(openAllStations.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        // state.stations = state.stations.map((station) => ({
        //   ...station,
        //   stationMetrics: {
        //     ...station.stationMetrics,
        //     door: 1,
        //   },
        // }))
      })
      .addCase(openAllStations.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(closeAllStations.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(closeAllStations.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        // state.stations = state.stations.map((station) => ({
        //   ...station,
        //   stationMetrics: {
        //     ...station.stationMetrics,
        //     door: 0,
        //   },
        // }));
      })
      .addCase(closeAllStations.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { updateStationsList } = userStationsSlice.actions;
export const userStationsReducer = userStationsSlice.reducer;
export default userStationsSlice;
