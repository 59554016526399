import { Outlet } from 'react-router-dom';
import {Header} from '../Header/Header';
import { Suspense } from 'react';
import { MainWrapper } from './Layout.styled';

export const Layout = () => {



  return (
    <MainWrapper>
      <Header />
      <Suspense fallback={null}>
        <Outlet />
      </Suspense>
    </MainWrapper>
  );
};