import React from 'react';
import { LogOut } from 'components/AdminMenu/AdminMenu.styled';
import { Container } from './UserMenu.styled';

const UserMenu = ({ user, handleLogout }) => {
  const currentYear = new Date().getFullYear();

  return (
    <Container>
      <div>
        <p>ПЗ "NEKEN-shelter"</p>
        <p>Версія: 27.0.1</p>
        <p>
          <span>&#169;</span> ТОВ "НЕКЕН", {currentYear}. Усі права захищені
        </p>
        <h4>Користувач: {user?.name}</h4>
      </div>
      <LogOut onClick={handleLogout}> Вийти </LogOut>
    </Container>
  );
};

export default UserMenu;
