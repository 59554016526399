// import { configureStore } from '@reduxjs/toolkit';
// import { persistStore } from 'redux-persist';

// import { stationsReducer } from './stations/stationsSlice';
// import { usersReducer } from './users/usersSlice';
// import { persistedAuthReducer } from './auth/authSlice';
// import { userStationsReducer } from './userStations/userStationSlice';
// import { gpioMessagesReducer } from './gpio/gpioSlice';

// import {
//   FLUSH,
//   REHYDRATE,
//   PAUSE,
//   PERSIST,
//   PURGE,
//   REGISTER,
// } from 'redux-persist';

// export const store = configureStore({
//   reducer: {
//     auth: persistedAuthReducer,
//     stations: stationsReducer,
//     users: usersReducer,
//     userStations: userStationsReducer,
//     gpioMessages: gpioMessagesReducer
//   },
//   middleware: getDefaultMiddleware => [
//     ...getDefaultMiddleware({
//       serializableCheck: {
//         ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
//       },
//     }),
//   ],
// });

// export const persistor = persistStore(store);

import { configureStore } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';

import { stationsReducer } from './stations/stationsSlice';
import { usersReducer } from './users/usersSlice';
import { persistedAuthReducer } from './auth/authSlice';
import { userStationsReducer } from './userStations/userStationSlice';
import { gpioMessagesReducer } from './gpio/gpioSlice';

import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';

export const store = configureStore({
  reducer: {
    auth: persistedAuthReducer,
    stations: stationsReducer,
    users: usersReducer,
    userStations: userStationsReducer,
    gpioMessages: gpioMessagesReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

