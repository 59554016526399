import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import config from "../../config.js";

axios.defaults.baseURL = config.BACKEND_URL;

export const fetchGPIOMessages = createAsyncThunk(
    'messages/fetchAll',
    async (_, thunkAPI) => {
      try {
        const response = await axios.get('/stations/gpio-messages');
        return response.data;
      } catch (e) {
        return thunkAPI.rejectWithValue(e.message);
      }
    }
  );